<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @submit="filterSubmit"
      @reset="handleReset"
    />
    <div class="system-btn user-btn">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="handleNew"
      >添加部门</el-button>
      <el-button
        size="small"
        @click="batchDelete"
      >删 除</el-button>
    </div>
    <div class="container">
      <div class="right">
        <DepartmentTable
          v-if="tableData"
          :tableColumn="tableColumn"
          :tableData="tableData"
          :selection="true"
          :numIndex="true"
          numWidth="100px"
          :isExpand="true"
          @edit="goToEdit"
          @delete="goToDelete"
          @view="goToView"
          @add="handleNew"
          @selection="handleSelectionChange"
        />
      </div>
    </div>
    <DepartmentDialog
      :title="title"
      :isShowDialog="isShowDialog"
      :forms="formArr"
      :formData="formData"
      :width="750"
      @submit="handleSubmit"
      @close="handleColse"
    />
    <DialogView
      title="查看"
      :isShowDialog="isShowView"
      :forms="formArr"
      :formData="formData"
      :width="750"
      @close="handleViewColse"
    />
  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive, ref } from "vue";
import { ElButton, ElInput, ElMessage, ElMessageBox } from "element-plus";
import { tableColumn, forms } from "./index.js";
import DepartmentTable from "@/components/table";
import DepartmentDialog from "@/components/dialog-form";
import DialogView from "@/components/dialog-view";
import { useStore } from "vuex";
import { reset } from "@/until";
import {
  postDepartment,
  putDepartment,
  deleteDepartment,
  batchDeleteDepartment,
} from "@/api/method/system";

export default {
  name: "SystemDepartment",
  components: {
    FilterForm,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    DepartmentTable,
    DepartmentDialog,
    DialogView,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      name: null,
      fullName: null,
    });
    const formData = reactive({
      id: null,
      name: null,
      fullName: null,
      parentId: null,
      type: null,
      channel: 0,
      visit: 1,
      sort: null,
      description: null,
    });
    const formArr = reactive(forms);
    const filters = reactive([
      [
        {
          label: "部门名称",
          type: "input",
          attr: "name",
          placeholder: "搜索部门名称",
          suffixIcon: "el-icon-search",
          column: 6,
          labelWidth: "80px",
        },
        {
          label: "部门全称",
          type: "input",
          attr: "fullName",
          placeholder: "搜索部门全称",
          suffixIcon: "el-icon-search",
          column: 6,
          labelWidth: "100px",
        },

        {
          type: "button",
          column: 12,
        },
      ],
    ]);

    const title = ref("添加");
    const company = ref("");

    const getDepartList = async () => {
      await dispatch("departmentList");
      formArr[1][0].option =
        state.departmentList &&
        state.departmentList.map((item) => ({
          label: item.fullName,
          val: item.id,
        }));
    };

    const getDepartmentType = async () => {
      await dispatch("departmentType");
      formArr[1][1].option =
        state.departmentType &&
        state.departmentType.map((item) => ({
          label: item.label,
          val: item.vlaue,
        }));
    };

    onMounted(() => {
      getList();
      getDepartList();
      getDepartmentType();
    });
    const tableData = computed(() => state.departmentTree);
    const getList = () => {
      dispatch("departmentTree", filterData);
    };

    const isShowDialog = ref(false);

    const handleNew = (id) => {
      isShowDialog.value = true;

      if (Number(id) || Number(id) == 0) {
        formArr[1][0].disabled = true;
        formData.parentId = id;
        title.value = "新增子项";
      } else {
        formArr[1][0].disabled = false;
        formData.parentId = null;
        title.value = "添加";
      }
    };

    const handleColse = () => {
      isShowDialog.value = false;
      reset(formData);
      formData.channel = 0;
      formData.visit = 1;
    };

    const handleSubmit = async () => {
      const { id, ...params } = formData;
      if (id) {
        const res = await putDepartment({ id, ...params });
        if (res.code == 200) {
          ElMessage.success("编辑成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      } else {
        const res = await postDepartment(params);
        if (res.code == 200) {
          ElMessage.success("保存成功！");
          handleColse();
          getList();
        } else {
          ElMessage.error(res.msg);
        }
      }
    };

    const idArr = ref([]);
    const handleSelectionChange = (arr) => {
      idArr.value = arr.map((item) => item.id);
    };

    const batchDelete = async () => {
      if (!idArr.value.length) {
        ElMessage.warning("请勾选部门后操作");
        return;
      }
      ElMessageBox.confirm("确定要删除这些部门吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await batchDeleteDepartment(idArr.value);
          if (res.code == 200) {
            idArr.value = [];
            ElMessage.success("批量删除部门成功！");
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    const getDepartmentDetail = (id) => {
      const {
        name,
        fullName,
        parentId,
        type,
        channel,
        visit,
        sort,
        description,
      } = state.departmentDetail;
      formData.id = id;
      formData.name = name;
      formData.fullName = fullName;
      formData.parentId = parentId;
      formData.type = type;
      formData.channel = channel;
      formData.visit = visit;
      formData.sort = sort;
      formData.description = description;
    };
    const goToEdit = async (id, parentId, str) => {
      isShowDialog.value = true;
      title.value = str ? "新增子项" : "编辑";
      await dispatch("departmentDetail", id);

      getDepartmentDetail(id);
      if (parentId == 0) {
        formData.parentId = 1;
      }
    };

    const goToDelete = (id) => {
      ElMessageBox.confirm("确定要删除该部门吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await deleteDepartment(id);
          if (res.code == 200) {
            ElMessage({
              type: "success",
              message: "删除成功!",
            });
            getList();
          } else {
            ElMessage({
              type: "error",
              message: res.msg,
            });
          }
        })
        .catch(() => {});
    };

    const isShowView = ref(false);
    const goToView = async (id) => {
      isShowView.value = true;
      await dispatch("departmentDetail", id);
      getDepartmentDetail(id);
      formData.channel = formData.channel ? "是" : "否";
      formData.visit = formData.visit ? "是" : "否";
      if (formData.parentId == 0) {
        formData.parentId = 1;
      }
      formData.parentId =
        state.departmentList &&
        state.departmentList.find((item) => item.id == formData.parentId)
          .fullName;
      formData.type =
        state.departmentType &&
        state.departmentType.find((item) => item.vlaue == formData.type).label;
    };

    const handleViewColse = () => {
      isShowView.value = false;
      reset(formData);
    };

    /**部门树搜索 */
    // const expandRow = ref([]);
    // const initData = (tableData, str) => {
    //   const data = handleTreeData(tableData, str);
    //   setExpandRow(data);
    //   expandRow.value = expandRow.value.join(",").split(",");
    //   return data;
    // };
    // const tableData = computed(() =>
    //   filterData.name
    //     ? initData(tableData.value, filterData.name)
    //     : state.departmentTree
    // );

    // const handleTreeData = (treeData, searchValue) => {
    //   if (!treeData || treeData.length === 0) {
    //     return [];
    //   }
    //   const array = [];
    //   for (let i = 0; i < treeData.length; i += 1) {
    //     let match = false;
    //     for (let pro in treeData[i]) {
    //       if (typeof treeData[i][pro] == "string") {
    //         match |= treeData[i][pro].includes(searchValue);
    //         if (match) break;
    //       }
    //     }
    //     if (
    //       handleTreeData(treeData[i].children, searchValue).length > 0 ||
    //       match
    //     ) {
    //       array.push({
    //         ...treeData[i],
    //         children: handleTreeData(treeData[i].children, searchValue),
    //       });
    //     }
    //   }
    //   return array;
    // };
    // const setExpandRow = (handleTreeData) => {
    //   if (handleTreeData.length) {
    //     for (let i of handleTreeData) {
    //       expandRow.value.push(i.id);
    //       if (i.children.length) {
    //         setExpandRow(i.children);
    //       }
    //     }
    //   }
    // };

    const filterSubmit = () => getList();
    const handleReset = async () => {
      filterData.name = null;
      filterData.fullName = null;
      getList();
    };

    return {
      formData,
      filters,
      company,
      tableColumn,
      tableData,
      handleNew,
      isShowDialog,
      formArr,
      filterData,
      handleColse,
      handleSubmit,
      title,
      handleViewColse,
      goToView,
      goToDelete,
      goToEdit,
      isShowView,
      handleSelectionChange,
      batchDelete,
      filterSubmit,
      handleReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  display: flex;
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block .el-input__inner {
  background: white !important;
}
</style>