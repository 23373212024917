<template>
  <el-dialog
    :title="title"
    :width="width"
    v-model="showDialog"
    :before-close="handleColse"
  >
    <el-form
      class="form-block"
      :inline="true"
      label-suffix=":"
    >
      <template
        v-for="(filter, index) in forms"
        :key="index"
      >
        <el-row>
          <template
            v-for="(item, ind) in filter"
            :key="ind"
          >
            <el-col :span="item.column">

              <el-form-item
                :label="item.label"
                :label-width="item.labelWidth"
              >
                <el-switch
                  v-model="formDatas[item.attr]"
                  v-if="item.type == 'switch'"
                  active-color="#406fff"
                  :active-value="1"
                  :inactive-value="0"
                  disabled
                />
                <div v-else-if="item.attr == 'deptId'">{{deptName}}</div>
                <div v-else-if="item.attr == 'roleId'">{{roleName}}</div>
                <div v-else-if="item.attr == 'sex'">{{sex}}</div>
                <div v-else>{{formDatas[item.attr]}}</div>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </template>
    </el-form>
  </el-dialog>
</template>

<script>
import {
  ElForm,
  ElFormItem,
  ElButton,
  ElRow,
  ElCol,
  ElDialog,
  ElSwitch,
} from "element-plus";
import { computed, reactive } from "vue";
import { useStore } from "vuex";

export default {
  name: "DialogView",
  props: ["forms", "formData", "title", "isShowDialog", "width"],
  components: {
    [ElFormItem.name]: ElFormItem,

    [ElForm.name]: ElForm,
    [ElButton.name]: ElButton,

    [ElRow.name]: ElRow,
    [ElCol.name]: ElCol,
    [ElDialog.name]: ElDialog,

    [ElSwitch.name]: ElSwitch,
  },
  setup(props, context) {
    const { state } = useStore();
    const formDatas = reactive(props.formData);
    const submitForm = () => {
      context.emit("submit");
    };

    const showDialog = computed(() => props.isShowDialog);

    const resetForm = () => {};
    const handleColse = () => context.emit("close");
    const deptName = computed(
      () =>
        state.departmentList &&
        formDatas.deptId &&
        state.departmentList.find((item) => item.id == formDatas.deptId)
          .fullName
    );

    const roleName = computed(
      () =>
        state.roleList &&
        formDatas.roleId &&
        state.roleList.find((item) => item.id == formDatas.roleId).name
    );
    const sex = computed(
      () => (formDatas.sex != null && (formDatas.sex == 0 ? "男" : "女")) || ""
    );

    return {
      submitForm,
      formDatas,
      resetForm,
      showDialog,
      handleColse,
      deptName,
      roleName,
      sex,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/css/form";
</style>