export const tableColumn = [
	{
		label: '部门名称',
		prop: 'name',
		
	},
	{
		label: '部门全称',
		prop: 'fullName',
		
	},
	{
		label: '部门类型',
		prop: 'type',
		width: '150px',
		isCustomize: true,
		tableType: 'departmentType',
	},
	{
		label: '是否上门入库',
		prop: 'visit',
		width: '150px',
        isCustomize: true,
        tableType: "isVisit"
	},
	{
		label: '排序',
		prop: 'sort',
		width: '150px',
	},
	{
		label: '操作',
		isCustomize: true,
		tableType: 'departmentOperate',
        width: "220px"
	},
]

export const forms = [
	[
		{
			label: '部门名称',
			type: 'input',
			attr: 'name',
			placeholder: '请输入部门名称',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '部门全称',
			type: 'input',
			attr: 'fullName',
			placeholder: '请输入部门全称',
			column: 12,
			labelWidth: '120px',
		},
	],

	[
		{
			label: '上级部门',
			type: 'select',
			attr: 'parentId',
			placeholder: '请选择上级部门',
			column: 12,
			labelWidth: '120px',
            disabled: false,
			option: [],
		},
		{
			label: '部门类型',
			type: 'select',
			attr: 'type',
			placeholder: '请选择部门类型',
			column: 12,
			labelWidth: '120px',
			option: [],
		},
	],
	[
		{
			label: '是否渠道',
			type: 'radio',
			attr: 'channel',
			placeholder: '',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '是', value: 1 },
				{ label: '否', value: 0 },
			],
		},
		{
			label: '上门入库',
			type: 'radio',
			attr: 'visit',
			placeholder: '',
			column: 12,
			labelWidth: '120px',
			option: [
				{ label: '是', value: 1 },
				{ label: '否', value: 0 },
			],
		},
	],
	[
		{
			label: '排序',
			type: 'inputNumber',
			attr: 'sort',
			placeholder: '请输入排序',
			column: 12,
			labelWidth: '120px',
		},
		{
			label: '备注',
			type: 'input',
			inputType: 'textarea',
			rows: 1,
			attr: 'description',
			placeholder: '请输入备注',
			column: 12,
			labelWidth: '120px',
		},
	],
]

export const formData = {}
